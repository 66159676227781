import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Counter } from '@/components/Counter'
import { Clock, Graph } from '@/assets/bodymovin/bodyMovinExports'
import BodyMovinContainer from '@/components/Bodymovin/BodymovinContainer'
import {
  CounterWrapper,
  CounterItem,
  CounterTitle,
  CounterHeader,
  CounterClock,
  CounterGraph,
} from './styled'
import gsap from 'gsap'
import { useContentInProp } from '@/hooks/useContentInProp'

interface Props {
  header1: string
  prefix1: string
  number1: number
  postfix1: string
  header2: string
  prefix2: string
  number2: number
  postfix2: string
  inProp?: boolean
}

const CounterContainer: React.FC<Props> = ({
  header1,
  prefix1,
  postfix1,
  header2,
  prefix2,
  postfix2,
}) => {
  const inProp = useContentInProp()
  const containerRef = useRef(null)
  const firstTitleRef = useRef(null)
  const secondTitleRef = useRef(null)

  useEffect(() => {
    if (!inProp) {
      return
    }

    const tl = gsap.timeline({ delay: 0.9 })
    const titles = [firstTitleRef, secondTitleRef].map((ref) => ref.current)

    tl.set(titles, { opacity: 1 })

    tl.fromTo(
      titles,
      0.5,
      {
        y: 20,
      },
      {
        y: 0,
        stagger: 0.1,
      },
    )
  }, [inProp])

  return (
    <CounterWrapper ref={containerRef}>
      <CounterItem>
        <CounterHeader>
          <CounterGraph>
            {inProp && <BodyMovinContainer source={Graph} />}
          </CounterGraph>
          <CounterTitle>
            <span ref={firstTitleRef}>{header1}</span>
          </CounterTitle>
        </CounterHeader>
        <Counter
          start={70}
          end={100}
          delay={0.9}
          fixed={0}
          prefix={prefix1}
          inProp={inProp}
          postfix={postfix1}
        />
      </CounterItem>
      <CounterItem>
        <CounterHeader>
          <CounterClock>
            {inProp && <BodyMovinContainer source={Clock} />}
          </CounterClock>
          <CounterTitle>
            <span ref={secondTitleRef}>{header2}</span>
          </CounterTitle>
        </CounterHeader>
        <Counter
          start={0.06}
          end={0.044}
          fixed={3}
          delay={1}
          prefix={prefix2}
          inProp={inProp}
          postfix={postfix2}
        />
      </CounterItem>
    </CounterWrapper>
  )
}

CounterContainer.propTypes = {
  header1: PropTypes.string.isRequired,
  postfix1: PropTypes.string.isRequired,
  header2: PropTypes.string.isRequired,
  postfix2: PropTypes.string.isRequired,
}

CounterContainer.defaultProps = {}

export default CounterContainer

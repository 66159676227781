import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import PropTypes from 'prop-types'

// eslint-disable-next-line
const BodyMovinContainer: React.FC<{ source: any }> = ({ source }) => {
  const animObjRef = useRef(null)
  const animRef = useRef(null)

  useEffect(() => {
    animObjRef.current = lottie.loadAnimation({
      animationData: source,
      autoplay: true,
      container: animRef.current,
      loop: false,
      renderer: 'svg',
    })

    return () => animObjRef.current.destroy()
  }, [])

  useEffect(() => {
    animObjRef.current.destroy()
    animObjRef.current = lottie.loadAnimation({
      animationData: source,
      autoplay: true,
      container: animRef.current,
      loop: false,
      renderer: 'svg',
    })
    animObjRef.current.play()
  }, [source])

  return <div ref={animRef} />
}

BodyMovinContainer.propTypes = {
  // eslint-disable-next-line
  source: PropTypes.object.isRequired,
}

export default BodyMovinContainer

import logoWhiteJson from './LogoWhite/logo-white.json'
import logoDarkJson from './LogoDark/logo.json'
import clockJson from './Clock/clock.json'
import graphJson from './Graph/graph.json'
import hamburgerJson from './hamburger/hamburger.json'
import hamburgerWhiteJson from './hamburger/hamburger_white.json'

export const LogoWhite = logoWhiteJson
export const LogoDark = logoDarkJson
export const Clock = clockJson
export const Graph = graphJson
export const Hamburger = hamburgerJson
export const HamburgerWhite = hamburgerWhiteJson

import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

export const CounterWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;

  ${breakpoints({
    xxl: css`
      align-items: center;
      flex-direction: row;
    `,
  })}
`

export const CounterItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
  ${breakpoints({
    xl: css`
      margin-bottom: 0;
      margin-right: 56px;
      &:last-child {
        margin-right: 0;
      }
    `,
  })}
`

export const CounterHeader = styled.div`
  align-items: center;
  display: flex;
  height: 35px;
`

export const CounterClock = styled.div`
  height: 30px;
  margin-right: 8px;
  width: 30px;
`

export const CounterGraph = styled.div`
  height: 60px;
  margin-right: -8px;
  margin-left: -17px;
  width: 60px;
`

export const CounterTitle = styled.div`
  overflow: hidden;
  font-size: 14px;
  text-transform: uppercase;

  span {
    opacity: 0;
    display: inline-block;
  }
`

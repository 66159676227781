import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'

export const Container = styled.div`
  opacity: 0;
  overflow: hidden;
  font-family: title;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-size: ${proportion(150, 'handheld')};
  line-height: ${proportion(150, 'handheld')};

  ${breakpoints({
    md: css`
      font-size: ${proportion(115, 'desktop')};
      line-height: ${proportion(115, 'desktop')};
    `,
    xl: css`
      font-size: 115px;
      line-height: 115px;

      letter-spacing: 0.22rem;
      margin-bottom: 3.4rem;
    `,
  })}
`

export const Wrap = styled.div`
  overflow: hidden;

  span {
    display: inline-block;
  }
`

export const Count = styled.span``

export const Text = styled.span`
  font-size: ${proportion(36, 'handheld')};

  ${breakpoints({
    xs: css`
      font-size: 40px;
    `,
    xl: css`
      font-size: 80px;
    `,
  })}
`

export const Char = styled.div`
  display: inline-block;
`

import React, { useRef, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Wrap, Count, Char, Text } from './styled'
import gsap from 'gsap'

interface Props {
  prefix: string
  postfix: string
  decrease?: number
  end: number
  fixed?: number
  interval?: number
  start: number
  text?: string
  delay?: number
  inProp?: boolean
}

function valueToCharacters(value: number, fixed = 2) {
  const val = value.toFixed(fixed).toString().split('')
  return val
}

export const Counter: React.FC<Props> = ({
  prefix,
  postfix,
  end,
  fixed,
  start,
  inProp = false,
  delay = 0,
}) => {
  const [chars, setChars] = useState(valueToCharacters(end, fixed))
  const counter = chars.map((char: string, key: number) => (
    /* eslint-disable-next-line react/no-array-index-key */
    <Char key={`counterchar-${key}`}>
      <span>{char}</span>
    </Char>
  ))
  const containerRef = useRef(null)
  const counterRef = useRef(null)
  const wrapRef = useRef(null)

  useLayoutEffect(() => {
    if (!inProp) {
      return
    }

    const tl = gsap.timeline({ delay })
    const spans = containerRef.current.getElementsByTagName('span')
    const progress = { value: 0 }

    tl.set(containerRef.current, { opacity: 1 })

    tl.fromTo(
      progress,
      2,
      {
        value: start,
      },
      {
        value: end,
        ease: 'sine.out',
        onUpdate: () => {
          setChars(valueToCharacters(progress.value, fixed))
        },
      },
      0,
    )

    tl.fromTo(
      wrapRef.current,
      0.5,
      {
        y: 80,
      },
      {
        y: 0,
        ease: 'sine.inOut',
      },
      0,
    )

    tl.fromTo(
      spans,
      0.5,
      {
        y: 40,
      },
      { y: 0, stagger: 0.05, ease: 'sine.inOut' },
      0,
    )
  }, [inProp])

  return (
    <Container ref={containerRef}>
      <Wrap ref={wrapRef}>
        <Count>
          <span>{prefix}</span>
        </Count>
        <Count ref={counterRef}>{counter}</Count>
        <Text>
          <span dangerouslySetInnerHTML={{ __html: postfix }} />
        </Text>
      </Wrap>
    </Container>
  )
}

Counter.defaultProps = {
  fixed: 2,
}

Counter.propTypes = {
  postfix: PropTypes.string.isRequired,
  end: PropTypes.number.isRequired,
  fixed: PropTypes.number,
  start: PropTypes.number.isRequired,
}
